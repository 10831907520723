import {
  Animation,
  AnimationController
} from "@ionic/angular";

export const navAnimation = (baseEl: HTMLElement, opts ? : any): Animation => {
  const DURATION = 100;

  const animationCtrl = new AnimationController();

  const enterAnimation = animationCtrl.create()
    .addElement(opts.enteringEl)
    .duration(DURATION)
    .easing('ease-in')
    .fromTo('opacity', 0, 1);

  const leaveEnteringAnimation = animationCtrl.create()
    .addElement(opts.leavingEl)
    .duration(DURATION)
    .easing('ease-out')
    .fromTo('opacity', 1, 0);

  const rootAnimation = animationCtrl.create()
    .addElement(opts.enteringEl)
    .duration(DURATION)
    .easing('ease-in')
    .fromTo('opacity', 0, 1);

  const leaveAnimation = animationCtrl.create().addAnimation([leaveEnteringAnimation, rootAnimation])

  let returnValue: any;
  switch (opts.direction) {
    case 'forward':
      returnValue = enterAnimation;
      break;

    case 'back':
      returnValue = leaveAnimation;
      break;

    default:
      returnValue = enterAnimation;
      break;
  }
  return returnValue;
};
