import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthenticationService } from './services/authentication.service';
import { Network } from '@capacitor/network';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit{

  canOpenMenu = true;
  orgName: string;
  email: string;

  public appPagesList1 = [
    { title: 'Bookings', url: '/bookings', icon: 'event', selected: true },
    { title: 'Services', url: '/services', icon: 'home_repair_service', selected: false },
    { title: 'Customers', url: '/customers', icon: 'people_alt', selected: false },
    { title: 'Resources', url: '/resources', icon: 'badge', selected: false },
  ];
  public appPagesList2 = [
    // { title: 'Plugins', url: '/plugins', icon: 'extension', selected: false },
    // { title: 'Availability', url: '/availability', icon: 'access_time_filled', selected: false },
    { title: 'Settings', url: '/settings', icon: 'settings', selected: false },
  ];
  public labels = [
    {title: 'Account', icon: 'manage_accounts'},
    {title: 'Roadmap', icon: 'map'},
    {title: 'Get help', icon: 'help'},
  ];
  
  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private menu: MenuController,
    private metaTagService: Meta,
    private titleService: Title,
  ) {}

  ngOnInit(){
    this.metaTagService.updateTag(
      { name: 'description', content: 'Quickly Schedule appointments and manage business right from your computer.' }
    );

    this.router.events.subscribe((event: NavigationStart)=>{
      if(event instanceof NavigationStart) {
        try {          
          let match = this.findUrlIndex(event.url);
          this.toggleColor(match.index, match.list);
        } catch (error) {
          if (event.url === '/services/create-service' || event.url === '/services/edit-service') {
            this.toggleColor(1, '1');
          }
        }
      }
    });
    this.logCurrentNetworkStatus();
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
    });
    
    window.onload = (event) => {console.log(event);}
    this.authService.currentCanOpenMenu.subscribe(data=>{
      this.canOpenMenu = data;
      if (data) {
        this.menu.open();
      }
    });
    this.authService.currentOrgName.subscribe(data=>{
      this.orgName = data;
    });
    this.authService.currentMailId.subscribe(data=>{
      this.email = data;
    });
  }
  ngAfterViewInit(){
  }
  
  async logCurrentNetworkStatus() {
    const status = await Network.getStatus();
  
    console.log('Network status:', status);
  };
  
  findUrlIndex(currentUrl: string){
    let match;
    this.appPagesList1.forEach((obj, index)=>{
      if (obj.url === currentUrl) {
        match = {index, list: '1'};
      }
    });
    this.appPagesList2.forEach((obj, index)=>{
      if (obj.url === currentUrl) {
        match = {index, list: '2'};
      }
    });
    return match;
  }

  toggleColor(i, list){
    this.unselectAll();
    this.selectOne(i, list);
  }
  unselectAll(){
    this.appPagesList1.forEach(item=>item.selected = false);
    this.appPagesList2.forEach(item=>item.selected = false);
  }
  selectOne(i, list){
    if (list === '1') {
      this.appPagesList1[i].selected = true;
      this.titleService.setTitle(`${this.appPagesList1[i].title} | GoSchedule`);
    } else{
      this.appPagesList2[i].selected = true;
      this.titleService.setTitle(`${this.appPagesList2[i].title} | GoSchedule`);
    }
  }

}
