import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule
} from '@angular/core';
import {
  BrowserModule
} from '@angular/platform-browser';
import {
  RouteReuseStrategy
} from '@angular/router';

import {
  IonicModule,
  IonicRouteStrategy
} from '@ionic/angular';

import {
  AppComponent
} from './app.component';
import {
  AppRoutingModule
} from './app-routing.module';
import {
  NZ_I18N
} from 'ng-zorro-antd/i18n';
import {
  en_US
} from 'ng-zorro-antd/i18n';
import {
  registerLocaleData
} from '@angular/common';
import en from '@angular/common/locales/en';
import {
  FormsModule, ReactiveFormsModule
} from '@angular/forms';
import {
  HttpClientModule
} from '@angular/common/http';
import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';

import { NzTableModule } from 'ng-zorro-antd/table';
registerLocaleData(en);

import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { navAnimation } from './animations/nav-animation';
import {MatIconModule} from '@angular/material/icon';
import {CloudinaryModule} from '@cloudinary/ng';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      navAnimation: navAnimation
    }),
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    NzTableModule,
    MatIconModule,
    CloudinaryModule
  ],
  providers: [{
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: NZ_I18N,
      useValue: en_US
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: ()=>()=>{},
      deps: [Sentry.TraceService],
      multi: true
     }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
