/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/member-delimiter-style */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Storage } from '@capacitor/storage';
import {
  BehaviorSubject,
  from,
  Observable
} from 'rxjs';
import {
  Router
} from '@angular/router';
import {
  map,
  switchMap,
  tap
} from 'rxjs/operators';
import { ModalController } from '@ionic/angular';

import * as Sentry from '@sentry/angular';

export const TOKEN_KEY = 'auth-token';
export const SETTINGS_COMPLETED = 'settings-completed';
export const BASE_URL = 'https://test.goschedule.io';
export const WIDGET_URL = 'https://goschedule.io';
// export const BASE_URL = 'http://localhost:3000';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {

  // baseUrl = 'http://localhost:3000';
  baseUrl = BASE_URL;

  isAuthenticated: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (null);
  currentIsAuthenticated = this.isAuthenticated.asObservable();

  canOpenMenu: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (false);
  currentCanOpenMenu = this.canOpenMenu.asObservable();

  authToken: string;

  public isAdmin: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (false);
  currentIsAdmin = this.isAdmin.asObservable();

  public isGoogleIntegrated: BehaviorSubject < boolean > = new BehaviorSubject < boolean > (false);
  currentIsGoogleIntegrated = this.isGoogleIntegrated.asObservable();

  public userName: BehaviorSubject < string > = new BehaviorSubject < string > (null);
  currentUserName = this.userName.asObservable();

  public orgName: BehaviorSubject < string > = new BehaviorSubject < string > (null);
  currentOrgName = this.orgName.asObservable();

  public orgId: BehaviorSubject < string > = new BehaviorSubject < string > ('0');
  currentOrgId = this.orgId.asObservable();

  public orgDetails: BehaviorSubject < any > = new BehaviorSubject < any > (null);
  currentOrgDetails = this.orgDetails.asObservable();

  public staffId: BehaviorSubject < string > = new BehaviorSubject < string > ('0');
  currentStaffId = this.staffId.asObservable();


  public mailId: BehaviorSubject < string > = new BehaviorSubject < string > (null);
  currentMailId = this.mailId.asObservable();

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private modalController: ModalController
  ) {
    this.canOpenMenu.next(false);
    this.loadToken();
    this.currentIsAdmin.subscribe(data=>console.log('is admin', data));

    this.currentIsAuthenticated.subscribe( async isAuthenticated => {
      console.log(`isAuthenticated => ${isAuthenticated}`);
      if (isAuthenticated) {
        try {
          await this.getOrgDetails();
          await this.getSelfDetails();
        } catch (error) {
          console.log(error);
        }
        
        const settingsCompleted = await Storage.get({
          key: SETTINGS_COMPLETED
        });
        console.log(`settingsCompleted.value => ${settingsCompleted.value}`);
        if (settingsCompleted.value === 'true') {
          this.canOpenMenu.next(true);
        }
    }});

  }

  async loadToken() {
    const token = await Storage.get({
      key: TOKEN_KEY
    });
    if (token.value) {
      this.authToken = token.value;
      console.log(this.authToken);
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  verifyUser(email){
    const myHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json');

  console.log(email);

  return this.httpClient.post(`${this.baseUrl}/passwordless/user`, JSON.stringify({email}), {
    headers: myHeaders
  });
  }

  signUp(credentials: {
    email: any,
  }): Observable < any > {

    const jsonData = {
      email: credentials.email,
    };

    const myHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    console.log(jsonData);

    return this.httpClient.post(`${this.baseUrl}/passwordless/signup`, JSON.stringify(jsonData), {
      headers: myHeaders
    });
  }

  verify(credentials: {
    uuid: any,
    code: any
  }): Observable < any > {
    const myHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    const jsonData = {
      uuid: credentials.uuid,
      code: credentials.code
      };

    return this.httpClient.post(`${this.baseUrl}/passwordless/verify`,JSON.stringify(jsonData), {
      headers: myHeaders
    }).pipe(
      map((data: any) => JSON.stringify(data)),
      map((data) => JSON.parse(data)),
      map((data) => {
        console.log(data);
        let str = JSON.stringify(data.token);
        str = str.substring(1, str.length-1);
        return {str, settingsCompleted: data.settingsCompleted};
      }),
      switchMap(token => {
        from(Storage.set({
        key: TOKEN_KEY,
        value: token.str
      }));
      return from(Storage.set({
        key: SETTINGS_COMPLETED,
        value: token.settingsCompleted
      }));
    }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    );
  }

  googleJwt(uuid): Observable < any > {
    const myHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    return this.httpClient.get(`${this.baseUrl}/google/getjwt/${uuid}`,{
      headers: myHeaders
    }).pipe(
      map((data: any) => JSON.stringify(data)),
      map((data) => JSON.parse(data)),
      map((data) => {
        console.log(data);
        let str = JSON.stringify(data.token);
        str = str.substring(1, str.length-1);
        return {str, settingsCompleted: data.settingsCompleted};
      }),
      switchMap(token => {
        from(Storage.set({
        key: TOKEN_KEY,
        value: token.str
      }));
      return from(Storage.set({
        key: SETTINGS_COMPLETED,
        value: token.settingsCompleted
      }));
    }),
      tap(async _ => {
        this.isAuthenticated.next(true);
      })
    );
  }

  login(credentials: {
    email: any
  }): Observable < any > {
    const myHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');

    const jsonData = {
      email: credentials.email,
      };

    return this.httpClient.post(`${this.baseUrl}/passwordless/signin`,JSON.stringify(jsonData), {
      headers: myHeaders
    });
  }

  async getOrgDetails() {
    const auth_token = await Storage.get({
      key: TOKEN_KEY
    });

    if (!auth_token.value) {
      this.logout();
    }

    const myHeaders = new HttpHeaders()
      .set('Authorization', auth_token.value);

    this.httpClient.get( `${this.baseUrl}/organization/get`, { //get organization meta data
      headers: myHeaders
    }).subscribe(async data=>{
      const value = JSON.parse(JSON.stringify(data));
      console.log(value);
      this.orgDetails.next(value.orgData);
      this.orgName.next(value.orgData.orgName);
      await Storage.set({
        key: SETTINGS_COMPLETED,
        value: value.orgData.settingsCompleted
      });
      if (!value.orgData.settingsCompleted) {
        // await this.router.navigateByUrl('/login');
      }
    },
    err=>{
      console.log(err);
    }
    );

  }

  async getSelfDetails() {
    const auth_token = await Storage.get({
      key: TOKEN_KEY
    });

    if (!auth_token.value) {
      this.logout();
    }

    const myHeaders = new HttpHeaders()
      .set('Authorization', auth_token.value);

    this.httpClient.get( `${this.baseUrl}/resource/self`, { //get resource meta data
      headers: myHeaders
    }).subscribe(data=>{
      const value = JSON.parse(JSON.stringify(data)).resData;
      console.log(value);
      this.userName.next(value.resourceName);
      this.mailId.next(value.email);
      const adminBoolean = value.roles.includes('admin');
      this.isAdmin.next(adminBoolean);
      const google = value.integrations.filter(element => element.name === 'google');
      let googleBoolean = false;
      console.log(value.integrations, 'heheheh');
      
      if (google.length) {
        googleBoolean = google[0].status;
      }
      console.log(`googleBoolean => ${googleBoolean}`);
      this.isGoogleIntegrated.next(googleBoolean);
      Sentry.configureScope(scope=>{
        scope.setUser({
          id: value._id,
          username: value.resourceName,
          email: value.email
        });
      });
    },
    err=>{
      console.log(err);
      if (err.status === 401) {
        console.log(err.status);
        this.logout();
      }
    }
    );

  }

  async logout(): Promise < boolean > {
    this.isAuthenticated.next(false);
    this.canOpenMenu.next(false);
    await Storage.clear();
    return this.router.navigateByUrl('/login');
    await document.location.reload(); 
    return true; 
  }

}
