import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { AuthenticationService, SETTINGS_COMPLETED } from '../services/authentication.service';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(private authService: AuthenticationService, private router: Router) { }

  canLoad(): Observable<boolean> {
    return this.authService.currentIsAuthenticated.pipe(
      filter(val => val !== null),
      take(1),
      map(isAuthenticated => {
        console.log('GUARD: ', isAuthenticated);
        if (isAuthenticated) {
          return true;
        } else {
          return false;
        }
      }),
      switchMap(async data=>{
        if (data) {
          let value = await Storage.get({ key: SETTINGS_COMPLETED });
          if (value) {
            let settingsDone = value.value === 'true';
            if (settingsDone) {
              return true
            } else{
              this.router.navigateByUrl('/login');
              return false;  
            }
          }
        } else{
          this.router.navigateByUrl('/login');
          return false;  
      }
      })
    );
  }
}
