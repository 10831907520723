import {
  Injectable
} from '@angular/core';
import {
  CanLoad,
  Router
} from '@angular/router';
import {
  Observable
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
  take
} from 'rxjs/operators';
import {
  AuthenticationService,
  SETTINGS_COMPLETED
} from '../services/authentication.service';
import {
  Storage
} from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class AutoLoginGuard implements CanLoad {
  constructor(private authService: AuthenticationService, private router: Router) {}


  canLoad(): Observable < boolean > {
    return this.authService.currentIsAuthenticated.pipe(
      filter(val => val !== null),
      take(1),
      map(isAuthenticated => {
        console.log(`isAuthenticated autologin => ${isAuthenticated}`);
        if (isAuthenticated) {
          // Directly open inside area
          return false;
        } else {
          // Simply allow access to the login
          return true;
        }
      }),
      switchMap(async data => {
        if (data) {
          return true;
        } else {
          let value = await Storage.get({
            key: SETTINGS_COMPLETED
          });
          if (value) {
            let settingsDone = value.value === 'true';
            if (settingsDone) {
              this.router.navigateByUrl('/bookings', {
                replaceUrl: true
              });
              return false;
            } else {
              return true;
            }
          }
        }
      })
    );
  }

}
