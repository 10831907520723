import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  dsn: environment.sentryUrl,
  release: `goschedule-web-1.0.1`,
  tracesSampleRate: 1.0,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['localhost', 'https://goschedule.io']
    })
  ]
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
